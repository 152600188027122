<template>
  <div>
    <v-container :fluid="fluid" class="pl-4 pr-4">
      <v-tabs v-model="tab">
        <v-tab>Cashflows</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-row>
            <v-col>
              <DenseCashflowTable
                :rows="econCashflowRows"
                :columns="econCashflowColumns"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <div></div>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </div>
</template>

<style>
.v-application .display-1 {
  font-size: 28px !important;
}
</style>

<script>
import { mapGetters } from "vuex";
import "vue-json-pretty/lib/styles.css";
import DenseCashflowTable from "../components/WIP/DenseCashflowTable.vue";

export default {
  name: "SingleActivitySlice",
  components: {
    DenseCashflowTable,
  },
  data() {
    return {
      tab: "",
      fluid: true,
    };
  },
  beforeCreate() {
    this.$store.dispatch("clearSingleActivitySlice");
  },
  beforeDestroy() {
    this.$store.dispatch("clearSingleActivitySlice");
  },
  mounted() {
    //

    this.$store.dispatch("clearSingleActivitySlice");
    this.$store.dispatch("fetchFinancialPolicies");
    this.$store.dispatch("fetchSliceProductsList");
    this.$store.dispatch("fetchSliceCostInputPeriods");
    this.$store.dispatch("fetchInterestRates");

    this.$store.dispatch(
      "getSingleActivitySlice",
      parseInt(this.$route.params.id)
    );
  },
  computed: {
    ...mapGetters([
      "singleActivitySlice",
      "singleActivitySliceInfoGrid",
      "sliceProductsList",
      "campaignProductsList",
      "variableShareCropSchedule",
      "isPageLoading",
    ]),

    econCashflowRows() {
      return this.singleActivitySlice?.economicCashflow?.rows;
    },
    econCashflowColumns() {
      return this.singleActivitySlice?.economicCashflow?.columns;
    },
  },
};
</script>
